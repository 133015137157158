import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import { cmsApiClient, CmsApiAcl } from "@cm-websites/common/dist/cms-api";
import {
  Explainer,
  LocalizationContext,
  ProductSelection,
  ResponsiveLineBreak,
} from "@cm-websites/common";
import { Footer } from "../footer";
import { HEADER_ITEMS } from "../header";
import { HeroHeader, Testimonials } from "../front-page";
import { MOCK_QUOTES } from "../front-page/testimonials/mock";
import { NextSeo } from "next-seo";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import i18nConfig from "../../next-i18next.config";
import Image from "next/legacy/image";
import Link from "next/link";
import MoodGeschenk from "../../public/images/front-page/gift-murder-mystery-party.jpg";
import MoodSpieleWand from "../../public/images/front-page/mood-murder-mysteries.jpg";
import React from "react";
import type { CmsApiModel } from "@cm-websites/common/dist/cms-api";
import type { GetStaticProps } from "next";
import type { Product } from "@cm-websites/common";

type HomeProps = {
  featuredProduct: Product;
  products: Product[];
};

export const HomePage: React.FC<HomeProps> = ({ featuredProduct, products }) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <NextSeo
        title="Murder Mystery Party Games for 5 – 8 Players | Culinario Mortale®"
        description="Murder mystery party games from Culinario Mortale. We provide all the materials you need to host the perfect Murder Mystery for 5 - 8 players."
        openGraph={{
          type: "website",
          url: "https://www.culinario-mortale.com",
          title: "Murder Mystery Party Games for 5 – 8 Players | Culinario Mortale®",
          description:
            "Murder mystery party games from Culinario Mortale. We provide all the materials you need to host the perfect Murder Mystery for 5 - 8 players.",
          images: [
            {
              url: "", // TODO
              width: 1200,
              height: 627,
              alt: "Murder Mystery Party Games for 5 – 8 Players | Culinario Mortale®",
            },
          ],
        }}
      />
      <HeroHeader navItems={HEADER_ITEMS} />
      <ProductSelection
        id="order"
        products={products}
        featuredProductId="CM07GB"
        recommendedProductIds={["CM03GB", "CM05GB"]}
      />
      <Explainer />
      <Box component="section" sx={{ position: "relative" }}>
        <Container
          maxWidth="xl"
          sx={{ pt: { xs: 8, sm: 10, md: 16 }, pb: { xs: 6, sm: 8, md: 10 }, overflowX: "hidden" }}
        >
          <Grid alignItems="center" container spacing={{ xs: 6, sm: 10 }}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                "> span": { borderRadius: theme.shape.borderRadius, overflow: "hidden" },
                pb: 0,
              }}
            >
              <Image alt="" placeholder="blur" title="" src={MoodSpieleWand} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h2" sx={{ pb: { xs: 3, md: 5 }, hyphens: "none" }}>
                There&apos;s murder on the menu
              </Typography>
              <Typography variant="body1" paragraph>
                Our Murder Mystery games promise you an evening full of excitement, intrigue and
                surprises. The best part is that the action takes place right at your own dining table.
                Our game is easy to understand. Since the <Link href="/rules">rules</Link> fit on only
                one page, even murder mystery beginners can easily get started.
              </Typography>

              <Typography variant="body1" paragraph>
                With the access code included in your Murder Mystery, you can easily{" "}
                <Link href="/preparation">download</Link> the role descriptions in advance and send them
                to your guests. This way, each participant can read up on his or her role at home and
                dress up accordingly before the evening. And with our{" "}
                <Link href="/recipes">menu suggestions</Link>, your dinner party will also be a culinary
                success.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box component="section" sx={{ position: "relative" }}>
        <Container
          maxWidth="xl"
          sx={{ pt: { xs: 0, sm: 0, md: 10 }, pb: { xs: 0, sm: 10 }, overflowX: "hidden" }}
        >
          <Grid
            alignItems="center"
            container
            direction={{ xs: "column-reverse", md: "row" }}
            spacing={{ xs: 6, sm: 10 }}
          >
            <Grid item xs={12} md={6}>
              <Typography variant="h2" sx={{ pb: { xs: 3, md: 5 }, hyphens: "none" }}>
                The perfect gift for <ResponsiveLineBreak md lg xl />
                murder mystery lovers
              </Typography>
              <Typography variant="body1" paragraph>
                Culinario Mortale makes for the perfect gift. Give your friends, family or colleagues
                their own Murder Mystery party that they can host themselves. Whether it’s for a
                birthday, New Year’s Eve party, or your next barbecue, our game boxes contain everything
                you’ll need for an unforgettable dinner party. Gift the most precious thing in the world:
                Time together with the people you love.
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              sx={{
                "> span": { borderRadius: theme.shape.borderRadius, overflow: "hidden" },
                pb: 0,
              }}
            >
              <Image alt="" placeholder="blur" title="" src={MoodGeschenk} />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Testimonials quotes={MOCK_QUOTES} sectionContainer={{ mt: 6, mb: 0 }} />
      <Footer />
    </React.Fragment>
  );
};

export default HomePage;

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const fields = [
    "*.*",
    "printVersionImages.*",
    "printVersionImages.directus_files_id.mask",
    "printVersionImages.directus_files_id.id",
    "printVersionImages.directus_files_id.description",
    "onlineVersionImages.*",
    "onlineVersionImages.directus_files_id.mask",
    "onlineVersionImages.directus_files_id.id",
    "onlineVersionImages.directus_files_id.description",
  ];

  const { data } = await cmsApiClient.get<{
    data: CmsApiModel.components["schemas"]["ItemsCulinarioMortale"][];
  }>(`/items/Culinario_Mortale?filter={"language":"GB"}&fields=${fields.join(",")}`);

  const products = (
    await Promise.all(
      data.data.map((p) => CmsApiAcl.product(p, "print", { origin: "startseite print" }))
    )
  ).sort((a, b) => {
    const nameA = a.id.toUpperCase(); // ignore upper and lowercase
    const nameB = b.id.toUpperCase(); // ignore upper and lowercase

    // sort in an descending order
    if (nameA < nameB) {
      return 1;
    }
    if (nameA > nameB) {
      return -1;
    }

    // names must be equal
    return 0;
  });
  const featuredProduct = products[0];

  return {
    props: {
      ...(locale
        ? await serverSideTranslations(
            locale,
            ["c-common", "c-product", "c-user-consent-management"],
            i18nConfig
          )
        : {}),
      products,
      featuredProduct,
    },
  };
};
