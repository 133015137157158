import { Box, Container, Grid, SxProps, Typography, useTheme } from "@mui/material";
import Image from "next/image";
import React from "react";
import Megaphone from "../../../public/images/front-page/megaphone.png";

export type Quote = {
  quote: string;
  source?: string;
};

type TestimonialsProps = {
  sectionContainer?: SxProps;
  quotes: Quote[];
};

export const Testimonials: React.FC<TestimonialsProps> = ({ sectionContainer = {}, quotes = [] }) => {
  const theme = useTheme();
  const bgColor = theme.palette.grey[50];
  const { quote, source } = quotes[0];

  return (
    <Box
      component="section"
      id="editions"
      sx={{
        backgroundColor: bgColor,
        boxShadow: "sectionVertical",
        position: "relative",
        zIndex: 5,
        ...sectionContainer,
      }}
    >
      <Container maxWidth="xl" sx={{ pt: { xs: 4, sm: 12 }, pb: { xs: 8, sm: 10 } }}>
        <Grid
          alignItems="center"
          container
          spacing={{ xs: 3, sm: 2 }}
          sx={{ flexDirection: { xs: "column-reverse", sm: "row" } }}
        >
          <Grid item xs={12} sm={7}>
            <Box component="figure" sx={{ px: 0, mx: 0, my: 0 }}>
              <Typography component="blockquote" variant="h2">
                {quote}
              </Typography>
              {source && (
                <Typography component="figcaption" variant="h5" sx={{ pt: 3 }}>
                  — <cite style={{ fontStyle: "normal" }}>{source}</cite>
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={0} sm={5}>
            <Box>
              <Image
                alt="Megafon"
                placeholder="blur"
                src={Megaphone}
                style={{ width: "100%", height: "auto" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
