import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { Header, HeaderOffset, HeroHeaderContainer, HeaderItem } from "@cm-websites/common";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Grid from "@mui/material/Unstable_Grid2";
import Image from "next/image";
import Mood from "../../../public/images/products/cm05/mood.jpg";
import NextLink from "next/link";
import React from "react";

type HeroHeaderProps = { navItems: HeaderItem[] };

export const HeroHeader: React.FC<HeroHeaderProps> = ({ navItems = [] }) => (
  <HeroHeaderContainer backgroundColor="#EDDBE3" id="frontpage-hero-header" sticky={false}>
    <Header items={navItems} />
    <HeaderOffset />
    <Container
      maxWidth="xl"
      sx={{
        overflow: { xs: "hidden", lg: "unset" },
        pb: { xs: 4, sm: 8, lg: 6 },
        pt: { xs: 0, sm: 8, lg: 3 },
      }}
    >
      <Grid
        alignItems="center"
        container
        spacing={{ xs: 2, sm: 4, lg: 8 }}
        direction={{ xs: "column-reverse", sm: "row" }}
      >
        <Grid xs={12} sm={7} lg={5}>
          <Box>
            <Typography
              variant="h1"
              sx={{ pb: { xs: 3, lg: 4 }, textAlign: { xs: "center", sm: "left" } }}
            >
              <Typography component="span" variant="overline" sx={{ display: "block" }}>
                There&apos;s murder on the menu
              </Typography>
              A Recipe for Death
            </Typography>
            <Typography variant="body1" paragraph sx={{ textAlign: { xs: "center", sm: "left" } }}>
              During the New Year’s Eve party at the famous McGregor restaurant, the renowned three-star
              chef George McGregor was found dead. What happened? Who murdered the old gourmet king so
              soon before his retirement? Murder Mystery game for 5 – 7 players. Now available.
            </Typography>

            <Stack
              alignItems="center"
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 0.5, sm: 1 }}
              sx={{ pt: { xs: 1, sm: 3 } }}
            >
              <Button
                endIcon={<ChevronRightIcon />}
                href="/murder-mystery-games/a-recipe-for-death"
                LinkComponent={NextLink}
                variant="contained"
                sx={{ width: { xs: "100%", sm: "unset" } }}
              >
                Read more
              </Button>
              <Button
                href="/what-is-a-murder-mystery-party"
                LinkComponent={NextLink}
                variant="text"
                sx={{ width: { xs: "100%", sm: "unset" } }}
              >
                What is a Murder Mystery?
              </Button>
            </Stack>
          </Box>
        </Grid>
        <Grid xs={12} sm={5} lg={7}>
          <Box
            sx={{
              "& > img": {
                borderRadius: "16px",
                overflow: "hidden",
                width: "100%",
                height: "auto",
              },
              transform: { xs: "scale(1.15)", sm: "scale(1.4)", md: "scale(1.3)", lg: "scale(1.0)" },
              borderRadius: "16px",
              display: "flex",
              flex: "0 0 100%",
              maskImage: 'url("/images/products/cm05/mood-mask.png")',
              maskPosition: "center",
              maskRepeat: "no-repeat",
              maskSize: "cover",
              mr: { xs: 0, sm: -5, md: -5, lg: 4 },
              overflow: "hidden",
            }}
          >
            <Image
              alt="A Recipe for Death – Murder Mystery Party Game for 5 – 7 Players"
              sizes={`
                (max-width: 767px) 80vw,
                (min-width: 768px) 28vw,
              `}
              placeholder="blur"
              priority
              src={Mood}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  </HeroHeaderContainer>
);
